import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';

const SearchInfo = ({styleTitle}) => {
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [errors, setErrors] = useState('');

  const handleSubmitPhoneNumber = (event) => {
    event.preventDefault();
    setErrors({message: ''});
    if (phoneNumber.length === 10) {
      navigate(`/userinfo/${phoneNumber}`);
    } else if (phoneNumber.length === 0) {
      setErrors({message: `Vui lòng điền số điện thoại để tra cứu`});
    } else {
      setErrors({message: `Số điện thoại cần 10 số`});
    }
  };

  const onInputPhoneNumber = (e) => {
    let phoneVal = /^0/.test(e.target.value) ? e.target.value.replace(/\D/g, '') : '';
    return setPhoneNumber(phoneVal);
  };

  return (
    <div className="w-full text-xl ">
      <form onSubmit={handleSubmitPhoneNumber} className="flex flex-col gap-3">
        <p className={`font-bold ${styleTitle}`}>Tra cứu thông tin khách hàng:</p>
        <div>
          <input
            maxLength={10}
            type="text"
            // pattern="\d{10}"
            className="w-full p-4 rounded-md border-primary  border overflow-auto font-semibold placeholder:text-lg"
            placeholder="Nhập số điện thoại"
            onChange={onInputPhoneNumber}
            autoComplete="off"
            name="phoneNumber"
            value={phoneNumber}
          />
        </div>
        {errors?.message ? <div className="text-red text-base pl-2">{errors.message}</div> : null}

        <button
          type="submit"
          className="bg-primary p-2 rounded-md font-bold text-white text-center w-full"
        >
          TRA CỨU
        </button>
      </form>
    </div>
  );
};
export default SearchInfo;
