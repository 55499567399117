import React from 'react';
import {useNavigate} from 'react-router-dom';
import moment from 'moment';
const PopupBlock = ({giftDetails, popupShow}) => {
  // return (
  //   <div
  //     id=""
  //     tabindex="1"
  //     aria-hidden="true"
  //     class="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full">
  //     <div class="relative w-full h-full max-w-2xl md:h-auto">
  //       <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
  //         <div class="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
  //           <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
  //             Terms of Service
  //           </h3>
  //           <button
  //             type="button"
  //             class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
  //             data-modal-toggle="defaultModal">
  //             <svg
  //               aria-hidden="true"
  //               class="w-5 h-5"
  //               fill="currentColor"
  //               viewBox="0 0 20 20"
  //               xmlns="http://www.w3.org/2000/svg">
  //               <path
  //                 fill-rule="evenodd"
  //                 d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
  //                 clip-rule="evenodd"></path>
  //             </svg>
  //             <span class="sr-only">Close modal</span>
  //           </button>
  //         </div>
  //         <div class="p-6 space-y-6">
  //           <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
  //             With less than a month to go before the European Union enacts new
  //             consumer privacy laws for its citizens, companies around the world
  //             are updating their terms of service agreements to comply.
  //           </p>
  //           <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
  //             The European Union’s General Data Protection Regulation (G.D.P.R.)
  //             goes into effect on May 25 and is meant to ensure a common set of
  //             data rights in the European Union. It requires organizations to
  //             notify users as soon as possible of high-risk data breaches that
  //             could personally affect them.
  //           </p>
  //         </div>
  //         <div class="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
  //           <button
  //             data-modal-toggle="defaultModal"
  //             type="button"
  //             class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
  //             I accept
  //           </button>
  //           <button
  //             data-modal-toggle="defaultModal"
  //             type="button"
  //             class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
  //             Decline
  //           </button>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );

  const navigate = useNavigate();
  const handleClick = () => {
    navigate(-1);
  };

  return (
    <div
      style={popupShow ? {display: 'block'} : {display: 'none'}}
      className=" fixed top-[50%] left-[50%] -translate-y-[50%] -translate-x-[50%]  z-20 shadow-2xl  w-[350px] bg-white rounded-3xl"
    >
      <div className="p-7 text-center flex flex-col gap-5 items-center">
        <div className="font-bold text-primary text-2xl">ĐỔI THÀNH CÔNG</div>
        <div>
          <div className="text-xl ">
            Ưu đãi: <span className="font-bold text-primary">{giftDetails?.discount}</span>
          </div>
          <div className="text-xl ">
            HSD:{' '}
            <span className="font-bold text-primary">
              {giftDetails?.expiryDateTime
                ? moment(giftDetails?.expiryDateTime).format('DD-MM-YYYY')
                : ''}
            </span>
          </div>
        </div>
        <div className="text-base">
          Mã khuyến mãi đã được gửi đến ứng dụng DPoint, khách hàng kiểm tra mục Quà đã đổi để sử
          dụng!
        </div>
        <div className="w-full">
          {/* <Link to={ROUTE_NAME.HOME}> */}
          <button onClick={handleClick} className="w-full rounded-lg text-white bg-primary py-2 ">
            Trở về trang tra cứu thông tin
          </button>
          {/* </Link> */}
        </div>
      </div>
    </div>
  );
};
export default PopupBlock;
