import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {Link, useNavigate} from 'react-router-dom';
import {ROUTE_NAME} from '../constant/const-variable';
import {addPhoneNumToState, addUserInfoToState} from '../../redux/reducer/userSlice';

function Header(props) {
  const navigate = useNavigate();

  const userPhoneNumFromState = useSelector((state) => state.userInfo?.phoneNumber);
  const dispatch = useDispatch();
  const {backBtnPath} = props;
  const handleClickBackBtn = () => {
    if (backBtnPath === ROUTE_NAME.HOME) {
      dispatch(addPhoneNumToState(''));
      dispatch(addUserInfoToState([]));
    } else if (backBtnPath === ROUTE_NAME.USERINFO) {
      navigate(`/userinfo/${userPhoneNumFromState}`);
    } else {
      navigate(-1);
    }
  };

  return (
    <div className="relative w-full">
      <header
        // style={props.popupShow ? { filter: "blur(10px)" } : {}}
        className=" fixed top-0 w-full z-10  flex items-center justify-center bg-primary text-white"
      >
        <div className="py-5 ">
          <Link to={backBtnPath || '/'}>
            <button
              onClick={handleClickBackBtn}
              className="text-xl absolute top-[1.25rem] left-[28px] flex gap-2 "
            >
              <span className="">
                <i className="fa-solid fa-chevron-left " />
              </span>
              <span>Trở về</span>
            </button>
          </Link>
          <h2 className="text-2xl font-bold">{props.title}</h2>
        </div>
      </header>
    </div>
  );
}
export default Header;
