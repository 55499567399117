import voucher50k from '../../images/50K.png';
import voucher100k from '../../images/100K.png';
import voucher200k from '../../images/200K.png';

import voucher500k from '../../images/500K.png';
import image_default from '../../images/image_default.png';

export const voucherSample = {
  id: 1,
  imgPath: image_default,
  nameVoucher: 'Voucher Nutipoint',
  valueVoucher: '50K',
  pointApply: '5000',
};
export const vouchersCanChange = [
  {
    id: 1,
    imgPath: voucher50k,
    nameVoucher: 'Voucher Nutipoint',
    valueVoucher: '50K',
    pointApply: '5000',
  },
  {
    id: 2,
    imgPath: voucher100k,
    nameVoucher: 'Voucher Nutipoint',
    valueVoucher: '100K',
    pointApply: '10000',
  },
  {
    id: 3,
    imgPath: voucher200k,
    nameVoucher: 'Voucher Nutipoint',
    valueVoucher: '200K',
    pointApply: '20000',
  },
  {
    id: 4,
    imgPath: voucher500k,
    nameVoucher: 'Voucher Nutipoint',
    valueVoucher: '500K',
    pointApply: '50000',
  },
  {
    id: 5,
    imgPath: voucher50k,
    nameVoucher: 'Voucher Nutipoint',
    valueVoucher: '50K',
    pointApply: '5000',
  },
  {
    id: 6,
    imgPath: voucher100k,
    nameVoucher: 'Voucher Nutipoint',
    valueVoucher: '100K',
    pointApply: '10000',
  },
  {
    id: 7,
    imgPath: voucher200k,
    nameVoucher: 'Voucher Nutipoint',
    valueVoucher: '200K',
    pointApply: '20000',
  },
  {
    id: 8,
    imgPath: voucher500k,
    nameVoucher: 'Voucher Nutipoint',
    valueVoucher: '500K',
    pointApply: '50000',
  },
];
export const vouchersCanNotChange = [
  {
    id: 1,
    imgPath: voucher50k,
    nameVoucher: 'Voucher Nutipoint',
    valueVoucher: 'Giảm 50K',
    pointApply: '5000',
  },
  {
    id: 2,
    imgPath: voucher100k,
    nameVoucher: 'Voucher Nutipoint',
    valueVoucher: 'Giảm 100K',
    pointApply: '10000',
  },
  {
    id: 3,
    imgPath: voucher200k,
    nameVoucher: 'Voucher Nutipoint',
    valueVoucher: 'Giảm 200K',
    pointApply: '20000',
  },
  {
    id: 4,
    imgPath: voucher500k,
    nameVoucher: 'Voucher Nutipoint',
    valueVoucher: 'Giảm 500K',
    pointApply: '50000',
  },
];
export const listStore = [
  {
    nameStore: 'CH Nutifood Lê Đức Thọ',
    addressStore: '670 Lê Đức Thọ, Phường 15, Quận Gò Vấp, Thành phố Hồ Chí Minh',
    phoneStore: '012 345 6789',
  },
  {
    nameStore: 'CH Nutifood Nguyễn Hữu Cảnh - Bình Thạnh',
    addressStore: '113A1 Nguyễn Hữu Cảnh, Phường 22, Quận Bình Thạnh, Thành phố Hồ Chí Minh',
    phoneStore: '012 345 6789',
  },
  {
    nameStore: 'CH Nutifood Nguyễn Thông - Quận 3',
    addressStore: '49 Nguyễn Thông, Phường 09, Quận 3, Thành phố Hồ Chí Minh',
    phoneStore: '012 345 6789',
  },
  {
    nameStore: 'CH Nutifood Lê Đức Thọ',
    addressStore: '670 Lê Đức Thọ, Phường 15, Quận Gò Vấp, Thành phố Hồ Chí Minh',
    phoneStore: '012 345 6789',
  },
  {
    nameStore: 'CH Nutifood Nguyễn Hữu Cảnh - Bình Thạnh',
    addressStore: '113A1 Nguyễn Hữu Cảnh, Phường 22, Quận Bình Thạnh, Thành phố Hồ Chí Minh',
    phoneStore: '012 345 6789',
  },
  {
    nameStore: 'CH Nutifood Nguyễn Thông - Quận 3',
    addressStore: '49 Nguyễn Thông, Phường 09, Quận 3, Thành phố Hồ Chí Minh',
    phoneStore: '012 345 6789',
  },
  {
    nameStore: 'CH Nutifood Nguyễn Hữu Cảnh - Bình Thạnh',
    addressStore: '113A1 Nguyễn Hữu Cảnh, Phường 22, Quận Bình Thạnh, Thành phố Hồ Chí Minh',
    phoneStore: '012 345 6789',
  },
  {
    nameStore: 'CH Nutifood Nguyễn Thông - Quận 3',
    addressStore: '49 Nguyễn Thông, Phường 09, Quận 3, Thành phố Hồ Chí Minh',
    phoneStore: '012 345 6789',
  },
  {
    nameStore: 'CH Nutifood Nguyễn Hữu Cảnh - Bình Thạnh',
    addressStore: '113A1 Nguyễn Hữu Cảnh, Phường 22, Quận Bình Thạnh, Thành phố Hồ Chí Minh',
    phoneStore: '012 345 6789',
  },
  {
    nameStore: 'CH Nutifood Nguyễn Thông - Quận 3',
    addressStore: '49 Nguyễn Thông, Phường 09, Quận 3, Thành phố Hồ Chí Minh',
    phoneStore: '012 345 6789',
  },
];
