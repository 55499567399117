import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import Layout from '../../component/layout';
import {ROUTE_NAME, TITLE} from '../../component/constant/const-variable';
import SearchInfo from '../../component/info-user/search-info';
import DetailsUserInfo from '../../component/info-user/details-info-user';
import ListVouchers from '../../component/voucher/list-vouchers';
import {getAllGiftAction, getMembershipCardAction} from '../../redux/actions/userInfo';

const UserInfoPage = () => {
  const params = useParams();
  const phoneNumber = params?.phoneNumber;
  const dispatch = useDispatch();

  useEffect(() => {
    if (phoneNumber) {
      dispatch(getAllGiftAction({phoneNumber}));
      dispatch(getMembershipCardAction({phoneNumber}));
    }
  }, [phoneNumber]);

  const giftsCanRedeem = useSelector((state) => state.userInfo.redeemableGifts);
  const giftsCannotRedeem = useSelector((state) => state.userInfo.nonRedeemableGifts);
  const dpointCard = useSelector((state) => state.userInfo.dpointCard);
  const merchantCard = useSelector((state) => state.userInfo.merchantCard);
  const errorMessage = useSelector((state) => state.userInfo.errorMessage);
  return (
    <div>
      <Layout
        backBtnPath={ROUTE_NAME.HOME}
        title={TITLE.SEARCH_INFO}
        contentFirstCol={
          <FirstCol
            merchantCard={merchantCard}
            dpointCard={dpointCard}
            phoneNumber={phoneNumber}
            errorMessage={errorMessage}
          />
        }
        contentSecondCol={
          <ListVouchers
            giftsCanRedeem={giftsCanRedeem}
            giftsCannotRedeem={giftsCannotRedeem}
            phoneNumber={phoneNumber}
          />
        }
      />
    </div>
  );
};

// First Column Content
const FirstCol = ({phoneNumber, merchantCard, dpointCard, errorMessage}) => {
  return (
    <div className="flex flex-col ">
      <SearchInfo styleTitle={phoneNumber ? 'text-blue2' : ''} />
      {!merchantCard && !dpointCard ? (
        <div className="mt-7 text-red text-base text-center">{errorMessage}</div>
      ) : (
        <DetailsUserInfo
          merchantCard={merchantCard}
          dpointCard={dpointCard}
          phoneNumber={phoneNumber}
        />
      )}
    </div>
  );
};

export default UserInfoPage;
