import axios from 'axios';
import {API_ENDPOINTS_HOST} from '../constants';
import {localStorageGetItem} from './storage';

const ACCESS_KEY_FROM_STORAGE = localStorageGetItem('accessKey')
  ? localStorageGetItem('accessKey')
  : '';

export const setAccessKeyValue = (AccessKey) => {
  if (AccessKey) {
    axios.defaults.headers.common['AccessKey'] = AccessKey;
  } else {
    delete axios.defaults.headers.common['AccessKey'];
  }
};

export function getApiBaseUrl(serviceEndpoint) {
  let service = '';

  switch (serviceEndpoint) {
    case API_ENDPOINTS_HOST.REACT_APP_IDENTITY_ADDRESS:
      return process.env.REACT_APP_IDENTITY_ADDRESS;

    case API_ENDPOINTS_HOST.REACT_APP_API_THIRD_PARTY:
      service = 'third-party';
      break;
    case API_ENDPOINTS_HOST.REACT_APP_API_TRANSACTION:
      service = 'transaction';
      break;

    case API_ENDPOINTS_HOST.REACT_APP_API_GAME:
      service = 'game';
      break;

    case API_ENDPOINTS_HOST.REACT_APP_API_CDP:
      service = 'cdp';
      break;

    case API_ENDPOINTS_HOST.REACT_APP_API_CMS:
      service = 'cms';
      break;

    case API_ENDPOINTS_HOST.REACT_APP_API_AUTH:
      service = 'auth/enduser';
      break;

    case API_ENDPOINTS_HOST.REACT_APP_API_MASTER_DATA:
      service = 'masterdata';
      break;

    case API_ENDPOINTS_HOST.REACT_APP_API_ADMIN_AUTH:
      service = 'auth/systemuser';
      break;

    case 'REACT_APP_API_ADS':
      service = 'ads';
      break;

    case API_ENDPOINTS_HOST.REACT_APP_API_CRM:
      service = 'crm';
      break;

    case API_ENDPOINTS_HOST.REACT_APP_API_DOCUMENT:
      service = 'document-upload';
      break;
    default:
      break;
  }

  return `${process.env.REACT_APP_API_URL}/${service}`;
}

export default function requestApi(
  serviceName,
  endpoint,
  method,
  body,
  responseType = 'json',
  accessKey = ACCESS_KEY_FROM_STORAGE
) {
  const baseUrl = getApiBaseUrl(serviceName);
  const accessKeyObject = !accessKey ? {} : {AccessKey: accessKey};

  return axios.request({
    method: method,
    url: `${baseUrl}${endpoint}`,
    headers: {
      ApiKey: process.env.REACT_APP_API_KEY,
      ...accessKeyObject,
    },
    data: body,
    responseType: responseType,
  });
}
