import {Link} from 'react-router-dom';
import React from 'react';
import imgDefault from '../../images/image_default.png';

const VoucherCard = ({item = {}, phoneNumber = ''}) => {
  return (
    <Link to={`/gift/${item?.id}`} state={{phoneNumber}}>
      <div className="col-span-1 relative h-full w-full rounded-md ">
        <div className="p-3 absolute bottom-0 flex flex-col gap-1 text-white rounded-b-md w-full bg-[linear-gradient(rgba(45,45,45,0.5),rgba(45,45,45,0.5))]">
          <p className="text-xs">{item?.name}</p>
          <p className="font-bold text-base">{item?.discount}</p>
          <p className="font-bold text-secondary text-sm">
            {item?.rewardValue} {item?.rewardName}
          </p>
        </div>
        <img
          className="rounded-md "
          src={item?.image ? item?.image : imgDefault}
          alt="voucher"
        ></img>
      </div>
    </Link>
  );
};

const VoucherGroup = ({dataListVoucher, phoneNumber}) => {
  if (dataListVoucher) {
    return (
      <div className="grid auto-rows-auto lg:grid-cols-4 md:grid-cols-3 gap-5">
        {dataListVoucher.map((item, index) => {
          return <VoucherCard key={index} item={item} phoneNumber={phoneNumber} />;
        })}
      </div>
    );
  }
};

const ListVouchers = ({giftsCanRedeem, giftsCannotRedeem, phoneNumber}) => {
  return (
    <div className="mb-10 h-full">
      {giftsCanRedeem?.length === 0 || !giftsCanRedeem ? (
        <div></div>
      ) : (
        <div className="mb-10">
          <p className="font-bold text-xl text-blue2 mb-5">Ưu đãi đổi được ngay</p>
          <div className="">
            <VoucherGroup dataListVoucher={giftsCanRedeem} phoneNumber={phoneNumber} />
          </div>
        </div>
      )}
      {giftsCannotRedeem?.length === 0 || !giftsCannotRedeem ? (
        <div></div>
      ) : (
        <div className="mb-10">
          <p className="font-bold text-xl text-blue2 mb-5">Ưu đãi chưa đủ điều kiện đổi</p>
          <div className="">
            <VoucherGroup dataListVoucher={giftsCannotRedeem} phoneNumber={phoneNumber} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ListVouchers;
