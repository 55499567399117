import addressImg from '../../images/location.png';
import phoneImg from '../../images/phone.png';
import OtpBlock from '../otp-block';
import * as actions from '../../redux/actions';
import {useDispatch} from 'react-redux';
import React, {useEffect, useState} from 'react';
import requestApi from '../../helper/apiHelper';
import parse from 'html-react-parser';
import imgDefault from '../../images/image_default.png';

export const VoucherFirstColDetail = (props) => {
  const [sendOTPBtn, setSendOTPBtn] = useState(true);
  const merchantPointOfUser = props?.merchantPointOfUser;
  /*const dispatch = useDispatch();
  const handleSendOtp = () => {
    sendOtpRequest();
  };*/

  // const phoneNumber = props?.phoneNumber;

  /*const sendOtpRequest = async () => {
    dispatch(createOtpRedeemGiftAction({ phoneNumber }));
  };*/

  let data = props.giftDetails;
  const conditionChangeVoucher =
    merchantPointOfUser >= data?.rewardValue && data?.remainingGifts > 0;

  return (
    <div className="w-full flex justify-center">
      <div className="lg:px-4 w-full m-0 flex flex-col gap-3">
        <div className="relative w-full rounded-md ">
          <img
            className="rounded-md w-full"
            src={data?.image ? data?.image : imgDefault}
            alt="voucher"
          ></img>
          <div className="p-4 absolute bottom-0 flex flex-col gap-1 text-white rounded-b-md w-full bg-[linear-gradient(rgba(45,45,45,0.7),rgba(45,45,45,0.7))]">
            <p className="text-lg">{data?.name}</p>
            <p className="font-bold text-2xl">{data?.discount}</p>
            <p className="font-bold text-secondary text-2xl">
              {data?.rewardValue} {data?.rewardName}
            </p>
          </div>
        </div>
        <div className="flex flex-col w-full gap-2">
          <div className="flex justify-between">
            <div className="text-gray3 lg:text-lg sm:text-base">Số lượng đổi</div>
            <div className="text-primary font-bold text-xl">{`1 ưu đãi`}</div>
          </div>
          <div className="flex justify-between">
            <div className="text-gray3 lg:text-lg sm:text-base">Tổng thanh toán</div>
            <div className="text-primary font-bold text-xl">
              {data?.rewardValue} {data?.rewardName}
            </div>
          </div>
          <div className="text-primary text-base">{`Bạn đang có ${
            merchantPointOfUser ? merchantPointOfUser : 0
          } ${data?.rewardName}`}</div>
        </div>
        <div>
          {conditionChangeVoucher ? (
            <button
              onClick={() => {
                props.onClickAcceptChangeVoucher();
                setSendOTPBtn(false);
              }}
              disabled={!sendOTPBtn}
              className={`w-full rounded-md text-lg py-2 font-semibold text-white ${
                sendOTPBtn ? 'bg-primary' : 'bg-gray4'
              }`}
            >
              XÁC NHẬN ĐỔI ƯU ĐÃI
            </button>
          ) : data?.remainingGifts <= 0 ? (
            <button
              disabled
              className="w-full rounded-md text-lg py-2 font-semibold bg-gray4 text-gray3"
            >
              QUÀ ĐÃ ĐƯỢC ĐỔI HẾT
            </button>
          ) : (
            <button
              disabled
              className="w-full rounded-md text-lg py-2 font-semibold bg-gray4 text-gray3"
            >
              CHƯA ĐỦ ĐIỀU KIỆN ĐỔI
            </button>
          )}
        </div>
        <OtpBlock
          acceptChangeVoucher={props.acceptChangeVoucher}
          // onClickCheckOTPBtn={props.onClickCheckOTPBtn}
          onClickSendOTPBtn={props.onClickAcceptChangeVoucher}
          sendOTPBtn={props.sendOTPBtn}
          // checkOtp={props.checkOtp}
          isCheckingOTP={props?.isCheckingOTP}
          onChangeOTPValue={props.onChangeOTPValue}
          otpValue={props.otpValue}
          phoneNumber={props.phoneNumber}
          onConfirmOTP={props?.onConfirmOTP}
          isRedeeming={props?.isRedeeming}
          giftId={data?.id}
        ></OtpBlock>
      </div>
    </div>
  );
};

export const VoucherSecondColDetail = (props) => {
  const [storeGift, setStoreGift] = useState(null);

  const dispatch = useDispatch();
  useEffect(() => {
    const getData = async () => {
      dispatch(actions.controlLoading(true));

      await requestApi(
        'REACT_APP_API_THIRD_PARTY',
        '/api/v1/kas-pos/masterdata/merchant/store',
        'GET'
      ).then((response) => {
        if (response.data?.isSuccess) {
          setStoreGift(response.data?.data);
        }
      });

      dispatch(actions.controlLoading(false));
    };
    getData();
  }, []);

  return (
    <div className="flex flex-col gap-3">
      <div>
        <p className="font-bold text-xl text-blue2 mb-2">Chi tiết quà tặng</p>
        <div className=" list-inside pl-4">
          {parse(props?.giftDetails?.shortDescription ? props?.giftDetails?.shortDescription : '')}
        </div>
      </div>
      <div>
        <p className="font-bold text-xl text-blue2 mb-2">Hướng dẫn sử dụng</p>
        <div className=" list-inside pl-4">
          {parse(props?.giftDetails?.useDescription ? props?.giftDetails?.useDescription : '')}
        </div>
      </div>
      <div>
        <p className="font-bold text-xl text-blue2 mb-2">Điều khoản sử dụng</p>
        <div className=" list-inside pl-4">
          {parse(
            props?.giftDetails?.conditionDescription ? props?.giftDetails?.conditionDescription : ''
          )}
        </div>
      </div>
      <div className="relative">
        <p className="font-bold text-xl text-blue2 mb-2">Cửa hàng áp dụng</p>
        <div className="absolute top-0 right-0 text-lg  font-bold text-primary ">{`${storeGift?.length} cửa hàng`}</div>
        <div className="flex flex-col gap-2 py-2 px-6 rounded-md border border-gray3 text-base divide-y divide-solid divide-gray2 h-[60vh] overflow-auto ">
          {storeGift?.map((item, index) => {
            return <InfoStore key={index} info={item}></InfoStore>;
          })}
        </div>
      </div>
    </div>
  );
};

const InfoStore = ({info}) => {
  return (
    <div className="pt-3 grid xl:grid-cols-3 md:grid-cols-5 md:grid-rows-2 grid-cols-1 last:pb-3">
      <div className="font-bold xl:col-span-1 md:col-span-2 text-primary">{info?.name}</div>
      <div className="flex gap-1 items-start xl:col-span-2 md:col-span-3  md:row-span-2">
        {(info?.location.address ||
          info?.location.wardName ||
          info?.location.districtName ||
          info?.location.provinceName) && (
          <div className="pt-1 w-5 ">
            <img
              style={{
                maxWidth: 20,
              }}
              src={addressImg}
              alt=""
            ></img>
          </div>
        )}

        <div>
          {`${info?.location.address ? info?.location.address + ', ' : ''}
            ${info?.location.wardName ? info?.location.wardName + ', ' : ''}
            ${info?.location.districtName ? info?.location.districtName + ', ' : ''}
            ${info?.location.provinceName ? info?.location.provinceName : ''}`}
        </div>
      </div>
      <div className="flex gap-1 items-center xl:col-span-1 md:col-span-2">
        <div>
          <img
            style={{
              height: 16,
            }}
            src={phoneImg}
            alt=""
          ></img>
        </div>

        <div>{info?.phone}</div>
      </div>
    </div>
  );
};
