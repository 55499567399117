import Header from '../header/index';
import Content from '../body/index';
import React from 'react';
import ControlAccessKey from '../input-accessKey';
// import { useDispatch } from "react-redux";
// import { togglePopUp } from "../../redux/reducer/giftSlice";

const Layout = (props) => {
  // const dispatch = useDispatch();
  return (
    <div
      className="App"
      // onClick={() => {
      //   if (props.popupShow) {
      //     dispatch(togglePopUp(false));
      //   }
      // }}
    >
      <Header
        // popupShow={props.popupShow}
        backBtnPath={props.backBtnPath}
        title={props.title}
      ></Header>
      <Content
        // popupShow={props.popupShow}
        contentFirstCol={props.contentFirstCol}
        contentSecondCol={props.contentSecondCol}
      ></Content>
      <ControlAccessKey />
    </div>
  );
};

export default Layout;
