import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {localStorageGetItem, localStorageSetItem} from '../../helper/storage';
import {getStoreAccessKeyAction} from '../../redux/actions/auth';
import {checkAccessKeyFromLocalStorage, resetAccessKeyState} from '../../redux/reducer/authSlice';
import {useNavigate} from 'react-router-dom';
import {setAccessKeyValue} from '../../helper/apiHelper';

const ControlAccessKey = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAccessKey = useSelector((state) => state.auth.accessKey.isAccessKey);
  const isAccessKeyFromLocalStorage = useSelector(
    (state) => state.auth.accessKey.isAccessKeyFromLocalStorage
  );

  const [accessKeyValue, setStateAccessKeyValue] = useState('');
  const [errorAccessKey, setErrorAccessKey] = useState({message: ''});

  useEffect(() => {
    const accessKey = localStorageGetItem('accessKey');
    setAccessKeyValue(accessKey);
  }, []);

  useEffect(() => {
    if (localStorageGetItem('accessKey')) {
      dispatch(checkAccessKeyFromLocalStorage(true));
    } else {
      dispatch(checkAccessKeyFromLocalStorage(false));
      dispatch(resetAccessKeyState());
    }
  }, [localStorageGetItem('accessKey')]);

  const handleSubmitAccessKey = (event) => {
    event.preventDefault();
    if (accessKeyValue.length < 32) {
      setErrorAccessKey({message: 'Vui lòng nhập Access Key 32 ký tự.'});
    } else {
      localStorageSetItem('accessKey', accessKeyValue);

      dispatch(getStoreAccessKeyAction({accessKey: accessKeyValue, callback: () => navigate('/')}));
    }
  };

  return (
    <div>
      {isAccessKeyFromLocalStorage ? (
        <></>
      ) : (
        <div>
          <div
            style={isAccessKey ? {display: 'none'} : {display: 'block'}}
            className=" fixed top-[50%] left-[50%] -translate-y-[50%] -translate-x-[50%]  z-20 text-center w-[450px] bg-white rounded-xl"
          >
            <form onSubmit={handleSubmitAccessKey} className="flex flex-col gap-3 p-5">
              <p className="font-bold text-xl">VUI LÒNG NHẬP ACCESS KEY:</p>
              <div>
                <input
                  type="text"
                  maxLength={32}
                  className="w-full text-lg p-4 rounded-md border-primary border overflow-auto font-semibold placeholder:text-lg"
                  placeholder="Nhập Access Key"
                  onChange={(e) => setStateAccessKeyValue(e.target.value)}
                  autoComplete="off"
                  name="accessKey"
                  value={accessKeyValue}
                />
              </div>
              {errorAccessKey?.message ? (
                <div className="text-red text-base pl-2">{errorAccessKey.message}</div>
              ) : (
                <></>
              )}

              <button
                type="submit"
                className={`${
                  accessKeyValue.length < 32 ? 'bg-gray4' : 'bg-primary'
                } p-2 rounded-md font-bold text-white text-center w-full`}
              >
                LƯU
              </button>
            </form>
          </div>
          {!isAccessKey ? (
            <div className="bg-[rgba(10,10,10,0.86)] fixed bottom-0 top-0 left-0 right-0 z-[15]"></div>
          ) : (
            <></>
          )}
        </div>
      )}
    </div>
  );
};
export default ControlAccessKey;
