import {createAsyncThunk} from '@reduxjs/toolkit';
import {getStoreAccessKeyApi} from '../../apis/third-party';
import {setAccessKeyValue} from '../../helper/apiHelper';
import {getErrorMessage} from '../../helper/handleError';

export const getStoreAccessKeyAction = createAsyncThunk(
  'auth/getStoreAccessKey',
  async ({accessKey, callback}, {rejectWithValue, fulfillWithValue}) => {
    try {
      const response = await getStoreAccessKeyApi(accessKey);
      const data = response?.data;
      if (data?.isSuccess && !!data?.data && !!data?.data?.accessKey) {
        setAccessKeyValue(accessKey);
        callback && callback();
        return fulfillWithValue(data?.data);
      } else {
        throw new Error('Access Key chưa chính xác!');
      }
    } catch (error) {
      return rejectWithValue(getErrorMessage(error, 'Access Key chưa chính xác!'));
    }
  }
);
