import React, {useState} from 'react';
import Countdown from 'react-countdown';

const COUNTDOWN_DELAY = 30 * 1000;

const OtpBlock = (props) => {
  const [countDownKey, setCountDownKey] = useState(0);
  const [countDownDate, setCountDownDate] = useState(Date.now());

  const countDownRenderer = ({seconds, completed}) => {
    if (completed) {
      return (
        <div
          className=" cursor-pointer font-bold text-blue2 underline"
          onClick={() => {
            setCountDownDate(Date.now() + COUNTDOWN_DELAY);
            setCountDownKey(countDownKey + 1);
            props?.onClickSendOTPBtn && props?.onClickSendOTPBtn();
          }}
        >
          Gửi lại mã OTP
        </div>
      );
    } else {
      return (
        <span className="cursor-pointer font-bold text-gray">
          Gửi lại OTP <span className="text-blue2">{`${seconds} giây`}</span>
        </span>
      );
    }
  };

  return (
    <div
      style={props.acceptChangeVoucher ? {} : {display: 'none'}}
      className="pt-5 mt-5 border-t border-gray flex flex-col gap-3"
    >
      <p className="font-bold lg:text-lg text-base text-center">
        Nhập OTP gửi đến số {`${props.phoneNumber}`}
      </p>
      <div className="">
        <input
          className="w-full rounded-md px-4 py-2 border border-primary  font-bold text-xl placeholder:text-base text-center"
          name="otp"
          onChange={props.onChangeOTPValue}
          type="text"
          value={props.otpValue}
          maxLength={4}
          placeholder="Nhập OTP"
        ></input>
      </div>
      <div>
        <button
          onClick={props?.onConfirmOTP}
          type="button"
          disabled={props?.isRedeeming || props.otpValue.length !== 4}
          className={` text-base p-2 rounded-md font-bold text-white text-center w-full ${
            props.otpValue.length === 4 ? 'bg-primary' : 'bg-gray4'
          }`}
        >
          {props?.isRedeeming ? 'ĐANG XỬ LÝ' : 'XÁC THỰC'}
        </button>
      </div>
      <div className="flex flex-col justify-between mb-6 gap-1">
        <div className="text-red text-base">{props?.isCheckingOTP}</div>
        <div>
          <Countdown key={countDownKey} date={countDownDate} renderer={countDownRenderer} />
        </div>
      </div>
    </div>
  );
};
export default OtpBlock;
