import React from 'react';
import {get} from 'lodash';
import {GENDERS} from '../../constants';
import moment from 'moment';

const Card = (props) => {
  return (
    <div
      style={props.bgColor}
      className="text-white p-2 rounded-md flex flex-col justify-between max-w-[200px] w-full h-[110px]"
    >
      <div className="flex gap-2 items-center">
        <div>
          <img style={{maxWidth: 37}} className="rounded-md" src={props.img} alt="logo"></img>
        </div>
        <p className="text-xs">{props.nameCard}</p>
      </div>
      <div className="text-right font-bold text-[14px]">{props.rank}</div>
      <div className="text-right text-sm">
        <span className="text-xl font-bold">{props.point}</span>
        {props.rewardName}
      </div>
    </div>
  );
};
const DetailsUserInfo = ({dpointCard, merchantCard, phoneNumber}) => {
  // const useInfoFromState = useSelector((state) => state.userInfo?.data);
  // const dpointCard = props.dpointCard;
  // const merchantCard = props.merchantCard;

  return (
    <div className="mt-7 pt-6 h-full  flex flex-col gap-7 items-center border-t border-blue2 ">
      <p className="text-xl font-bold text-blue2 ">Thông tin khách hàng</p>
      <div className="flex gap-3 flex-row w-full justify-center">
        {!!dpointCard && (
          <Card
            nameCard={dpointCard?.programName}
            bgColor={{
              background: 'linear-gradient(to bottom, #ff5b7d, #2d9aff)',
            }}
            img={dpointCard?.logo}
            rank={dpointCard?.tierName}
            point={Number(dpointCard?.rewardValue?.toFixed(2)) + ' Điểm'}
          />
        )}
        {!!merchantCard && (
          <Card
            nameCard={merchantCard.programName}
            bgColor={{background: merchantCard?.tierColor}}
            img={merchantCard?.logo}
            rank={merchantCard?.tierName}
            point={Number(merchantCard?.rewardValue?.toFixed(2)) + ' Điểm'}
          />
        )}
      </div>
      <div className=" w-full px-4">
        <table className="border-collapse w-full">
          <tbody>
            <tr>
              <th className="py-3 pr-3 text-left text-blue2 border-b border-gray2 font-normal">
                Họ tên:
              </th>
              <td className="py-3 text-left text-blue2 border-b border-gray2 font-bold">
                {dpointCard?.memberName}
              </td>
            </tr>
            <tr>
              <th className="py-3 pr-3 text-left text-blue2 border-b border-gray2 font-normal">
                Số điện thoại:
              </th>
              <td className="py-3 text-left text-blue2 border-b border-gray2 font-bold">
                {dpointCard?.phoneNumber ?? merchantCard?.phoneNumber ?? phoneNumber}
              </td>
            </tr>
            <tr>
              <th className="py-3 pr-3 text-left text-blue2 border-b border-gray2 font-normal">
                Ngày sinh:
              </th>
              <td className="py-3 text-left text-blue2 border-b border-gray2 font-bold">
                {dpointCard?.dateOfBirth ?? merchantCard?.dateOfBirth
                  ? moment(dpointCard?.dateOfBirth ?? merchantCard?.dateOfBirth).format(
                      'DD/MM/YYYY'
                    )
                  : ''}
              </td>
            </tr>
            <tr>
              <th className="py-3 pr-3 text-left text-blue2 border-b border-gray2 font-normal">
                Giới tính:
              </th>
              <td className="py-3 text-left text-blue2 border-b border-gray2 font-bold">
                {get(GENDERS, dpointCard?.gender ?? merchantCard?.gender, '')}
              </td>
            </tr>
            <tr>
              <th className="py-3 pr-3 text-left text-blue2 border-b border-gray2 font-normal">
                Email
              </th>
              <td className="py-3 text-left text-blue2 border-b border-gray2 font-bold">
                {dpointCard?.email ?? merchantCard?.email}
              </td>
            </tr>
            <tr>
              <th className="py-3 pr-3 text-left text-blue2 border-b border-gray2 font-normal">
                Địa chỉ
              </th>
              <td className="py-3 text-left text-blue2 border-b border-gray2 font-bold">
                {dpointCard?.address ?? merchantCard?.address}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DetailsUserInfo;
