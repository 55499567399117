import React from 'react';
import Layout from '../../component/layout';
import SearchInfo from '../../component/info-user/search-info';
import {TITLE} from '../../component/constant/const-variable';

const contentSecondCol = () => {
  return (
    <div className="w-full h-full">
      <p className="text-xl font-bold">Danh sách ưu đãi của chương trình</p>
      <div className=" w-full h-full flex justify-center items-center">
        <div className="text-center text-2xl font-bold max-w-[600px]">
          VUI LÒNG TRA CỨU THÔNG TIN THÀNH VIÊN ĐỂ THẤY ĐƯỢC VOUCHER CÓ THỂ ĐỔI
        </div>
      </div>
    </div>
  );
};
const Home = () => {
  return (
    <div>
      <Layout
        title={TITLE.SEARCH_INFO}
        contentFirstCol={<SearchInfo />}
        contentSecondCol={contentSecondCol()}
      />
    </div>
  );
};
export default Home;
