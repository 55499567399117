import {createSlice} from '@reduxjs/toolkit';
import {toast} from 'react-toastify';
import {
  createOtpRedeemGiftAction,
  createRedeemGiftAction,
  getGiftDetailAction,
} from '../actions/gift';

export const giftSlice = createSlice({
  name: 'gift',
  initialState: {
    detail: null,
    showPopUp: false,
    confirmExchange: false,
    isRedeeming: false,
    isCheckingOTP: '',
  },
  reducers: {
    togglePopUp: (state, {payload}) => {
      state.showPopUp = payload;
    },
    toggleConfirmExchange: (state, {payload}) => {
      state.confirmExchange = payload;
    },
    resetState: (state) => {
      state.showPopUp = false;
      state.confirmExchange = false;
      state.isCheckingOTP = '';
    },
  },
  extraReducers: {
    [getGiftDetailAction.pending]: (state) => {
      state.detail = null;
    },
    [getGiftDetailAction.fulfilled]: (state, {payload}) => {
      state.detail = payload;
    },
    [getGiftDetailAction.rejected]: (state, {payload}) => {
      state.detail = null;
      toast(payload, {type: 'error'});
    },
    [createOtpRedeemGiftAction.fulfilled]: () => {
      toast('Gửi OTP thành công!', {type: 'success'});
    },
    [createOtpRedeemGiftAction.rejected]: (state, {payload}) => {
      toast(payload, {type: 'error'});
    },
    [createRedeemGiftAction.pending]: (state, {payload}) => {
      state.isRedeeming = true;
      state.isCheckingOTP = '';
      toast(payload, {type: 'error'});
    },
    [createRedeemGiftAction.fulfilled]: (state) => {
      state.showPopUp = true;
      state.confirmExchange = false;
      state.isRedeeming = false;
      state.isCheckingOTP = '';
      toast('Xác nhận ưu đãi thành công!', {type: 'success'});
    },
    [createRedeemGiftAction.rejected]: (state, {payload}) => {
      state.isRedeeming = false;
      state.isCheckingOTP = payload === 'Mã OTP không hợp lệ ' ? 'OTP chưa chính xác' : '';
      toast(payload, {type: 'error'});
    },
  },
});
export const {togglePopUp, toggleConfirmExchange, resetState} = giftSlice.actions;
export default giftSlice.reducer;
