import React from 'react';
import {Route, Routes} from 'react-router-dom';
import Home from './pages/Home';
import UserInfoPage from './pages/UserInfo';
import VoucherDetailPage from './pages/Voucher';
import {ROUTE_NAME} from './component/constant/const-variable';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path={ROUTE_NAME.HOME} element={<Home />} />
        <Route path={ROUTE_NAME.USERINFO} element={<UserInfoPage />} />
        <Route path={ROUTE_NAME.VOUCHERDETAIL} element={<VoucherDetailPage />} />
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;
