import {configureStore} from '@reduxjs/toolkit';
import userReducer from '../reducer/userSlice';
import giftReducer from '../reducer/giftSlice';
import authSlice from '../reducer/authSlice';
export default configureStore({
  reducer: {
    userInfo: userReducer,
    gift: giftReducer,
    auth: authSlice,
  },
});
