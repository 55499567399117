import {createAsyncThunk} from '@reduxjs/toolkit';
import {retryApi} from '../../apis';
import {
  createOtpRedeemGiftApi,
  createRedeemGiftApi,
  getGiftDetailApi,
} from '../../apis/third-party';
import {getErrorMessage} from '../../helper/handleError';

export const getGiftDetailAction = createAsyncThunk(
  'gift/getGiftDetail',
  async ({phoneNumber, giftId}, {rejectWithValue, fulfillWithValue}) => {
    try {
      const response = await getGiftDetailApi(phoneNumber, giftId);
      const data = response?.data;
      if (data?.isSuccess && !!data?.data) {
        return fulfillWithValue(data?.data);
      } else {
        throw new Error('Lấy thông tin ưu đãi thất bại!');
      }
    } catch (error) {
      return rejectWithValue(getErrorMessage(error, 'Lấy thông tin ưu đãi thất bại!'));
    }
  }
);

export const createOtpRedeemGiftAction = createAsyncThunk(
  'gift/createOtpRedeemGift',
  async ({phoneNumber}, {rejectWithValue, fulfillWithValue}) => {
    try {
      const payload = {
        phoneNumber: phoneNumber,
        RequestType: 'REDEEM_GIFT_BY_MERCHANT',
        deviceId: 'web',
        deviceType: 1,
        userIPAddress: '',
        Source: '4',
      };
      const response = await createOtpRedeemGiftApi(payload);
      const data = response?.data;
      if (data?.isSuccess && !!data?.data) {
        return fulfillWithValue(data?.data);
      } else {
        throw new Error('Gửi OTP thất bại!');
      }
    } catch (error) {
      return rejectWithValue(getErrorMessage(error, 'Gửi OTP thất bại!'));
    }
  }
);

export const createRedeemGiftAction = createAsyncThunk(
  'gift/createRedeemGift',
  async ({phoneNumber, giftId, otp}, {rejectWithValue, fulfillWithValue}) => {
    try {
      const payload = {
        phoneNumber,
        giftId,
        quantity: 1,
        otp,
      };
      const resReceiptData = await createRedeemGiftApi(payload);
      const receiptData = resReceiptData?.data;
      if (receiptData?.isSuccess && receiptData?.data) {
        const response = await retryApi(receiptData);
        if (response?.data?.data?.isSuccess) {
          return fulfillWithValue({...payload});
        } else {
          throw new Error(response?.data?.data?.detail || 'Xác nhận ưu đãi thất bại!');
        }
      } else {
        throw new Error('Xác nhận ưu đãi thất bại!');
      }
    } catch (error) {
      return rejectWithValue(getErrorMessage(error, 'Xác nhận ưu đãi thất bại!'));
    }
  }
);
