import {createSlice} from '@reduxjs/toolkit';
// import {find, groupBy} from 'lodash';
import {filter, find} from 'lodash';
import {getAllGiftAction, getMembershipCardAction} from '../actions/userInfo';

export const userSlice = createSlice({
  name: 'userInfo',
  initialState: {
    phoneNumber: '',
    data: [],
    redeemableGifts: [],
    nonRedeemableGifts: [],
    dpointCard: null,
    merchantCard: null,
    errorMessage: '',
  },
  reducers: {
    addUserInfoToState: (state, {payload}) => {
      state.data = payload;
    },
    addPhoneNumToState: (state, {payload}) => {
      state.phoneNumber = payload;
    },
    resetUserInfo: (state) => {
      state.phoneNumber = '';
      state.data = [];
      state.redeemableGifts = [];
      state.nonRedeemableGifts = [];
      state.errorMessage = '';
    },
  },
  extraReducers: {
    [getAllGiftAction.pending]: (state) => {
      state.redeemableGifts = [];
      state.nonRedeemableGifts = [];
      state.errorMessage = '';
    },
    [getAllGiftAction.fulfilled]: (state, {payload}) => {
      // const groupedGift = groupBy(payload, 'canRedeemByMerchantReward');
      // state.redeemableGifts = groupedGift.true;
      // state.nonRedeemableGifts = groupedGift.false;
      state.redeemableGifts = filter(
        payload,
        (item) => item?.canRedeemByMerchantReward === true && item?.remainingGifts > 0
      );
      state.nonRedeemableGifts = filter(
        payload,
        (item) => item?.canRedeemByMerchantReward === false || item?.remainingGifts <= 0
      );
      state.errorMessage = '';
    },
    [getAllGiftAction.rejected]: (state, {payload}) => {
      state.redeemableGifts = [];
      state.nonRedeemableGifts = [];
      // toast(payload, { type: "error" });
      state.errorMessage = payload;
    },
    [getMembershipCardAction.pending]: (state) => {
      state.data = [];
      state.merchantCard = null;
      state.dpointCard = null;
    },
    [getMembershipCardAction.fulfilled]: (state, {payload}) => {
      state.data = payload;
      state.merchantCard = find(payload, (item) => item?.isDefault === false) || null;
      state.dpointCard = find(payload, (item) => item?.isDefault === true) || null;
    },
    [getMembershipCardAction.rejected]: (state) => {
      state.data = [];
      state.merchantCard = null;
      state.dpointCard = null;
      // toast(payload, { type: "error" });
    },
  },
});
export const {addUserInfoToState, addPhoneNumToState, resetUserInfo} = userSlice.actions;
export default userSlice.reducer;
