import {actionResultThirdPartyApi} from './third-party';
export const RETRY_TYPES = {
  TRANSACTION: 'transaction',
  CMS: 'cms',
  AUTH: 'auth',
  GAME: 'game',
  THIRD_PARTY: 'third-party',
};
const delay = async (wait) => {
  return new Promise((resolve) => setTimeout(resolve, wait));
};

export const retryApi = async (receiptNoData, type = RETRY_TYPES.THIRD_PARTY) => {
  const clientRetryAfterMs = receiptNoData.data?.clientRetryAfterMs || 1000;
  const maxNumberOfRetries = receiptNoData.data?.maxNumberOfRetries || 6;
  const nextClientRetryAfterMs = receiptNoData.data?.nextClientRetryAfterMs || 1500;
  const data = receiptNoData?.data;
  let count = 1;
  let result = {};
  await delay(clientRetryAfterMs);
  while (result?.data?.data?.status !== 1 && count <= maxNumberOfRetries) {
    if (count > 1) await delay(nextClientRetryAfterMs);
    count++;
    switch (type) {
      default:
        result = await actionResultThirdPartyApi(data);
        break;
    }
  }
  return result;
};
