import {API_ENDPOINTS_HOST} from '../../constants';
import requestApi from '../../helper/apiHelper';

export const getAllGiftApi = (phoneNumber) => {
  return requestApi(
    API_ENDPOINTS_HOST.REACT_APP_API_THIRD_PARTY,
    `/api/v1/kas-pos/masterdata/all-gifts/${phoneNumber}`,
    'GET'
  );
};

export const getMembershipCardsApi = (phoneNumber) => {
  return requestApi(
    API_ENDPOINTS_HOST.REACT_APP_API_THIRD_PARTY,
    `/api/v1/kas-pos/enduser/membership-card/default-and-merchant/${phoneNumber}`,
    'GET'
  );
};

export const getGiftDetailApi = (phoneNumber, giftId) => {
  return requestApi(
    API_ENDPOINTS_HOST.REACT_APP_API_THIRD_PARTY,
    `/api/v1/kas-pos/masterdata/gift/details/${phoneNumber}/${giftId}`,
    'GET'
  );
};

export const createOtpRedeemGiftApi = (payload) => {
  return requestApi(
    API_ENDPOINTS_HOST.REACT_APP_API_THIRD_PARTY,
    '/api/v1/kas-pos/transaction/redeem-gift/create/otp',
    'POST',
    payload
  );
};

export const createRedeemGiftApi = (payload) => {
  return requestApi(
    API_ENDPOINTS_HOST.REACT_APP_API_THIRD_PARTY,
    '/api/v1/kas-pos/transaction/redeem-gift/create',
    'POST',
    payload
  );
};

export const actionResultThirdPartyApi = (payload) => {
  return requestApi(
    API_ENDPOINTS_HOST.REACT_APP_API_THIRD_PARTY,
    '/api/v1/kas-pos/transaction/action-result/inquire',
    'POST',
    payload
  );
};

export const getStoreAccessKeyApi = (accessKey) => {
  return requestApi(
    API_ENDPOINTS_HOST.REACT_APP_API_THIRD_PARTY,
    `/api/v1/kas-pos/masterdata/store-access-key/details/by-accesskey/${accessKey}`,
    'GET',
    null,
    'json',
    accessKey
  );
};
