import {createSlice} from '@reduxjs/toolkit';
import {toast} from 'react-toastify';

import {getStoreAccessKeyAction} from '../actions/auth';
const initialState = {
  accessKey: {
    isLoading: false,
    isAccessKey: false,
    data: {},
    isAccessKeyFromLocalStorage: false,
  },
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    checkAccessKeyFromLocalStorage: (state, {payload}) => {
      state.accessKey.isAccessKeyFromLocalStorage = payload;
    },
    resetAccessKeyState: (state) => {
      state.accessKey.isAccessKey = false;
      state.accessKey.data = {};
    },
  },
  extraReducers: {
    [getStoreAccessKeyAction.pending]: (state) => {
      state.accessKey.isLoading = true;
      state.accessKey.isAccessKey = false;
      state.accessKey.data = {};
    },
    [getStoreAccessKeyAction.fulfilled]: (state, {payload}) => {
      state.accessKey.isLoading = false;
      state.accessKey.isAccessKey = true;
      state.accessKey.data = payload;
    },
    [getStoreAccessKeyAction.rejected]: (state, {payload}) => {
      localStorage.removeItem('accessKey');
      state.accessKey.isLoading = false;
      state.accessKey.isAccessKey = false;
      state.accessKey.data = {};
      toast(payload, {type: 'error'});
    },
  },
});

export const {checkAccessKeyFromLocalStorage, resetAccessKeyState} = authSlice.actions;
export default authSlice.reducer;
