import Layout from '../../component/layout';
import {TITLE} from '../../component/constant/const-variable';
import {listStore} from '../../component/voucher/voucher-sample';
import {
  VoucherFirstColDetail,
  VoucherSecondColDetail,
} from '../../component/voucher/voucher-detail';
import PopupBlock from '../../component/otp-block/popup';
import {useDispatch, useSelector} from 'react-redux';
import React, {useEffect, useState} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import {
  createOtpRedeemGiftAction,
  createRedeemGiftAction,
  getGiftDetailAction,
} from '../../redux/actions/gift';
import {getMembershipCardAction} from '../../redux/actions/userInfo';
import {resetState, toggleConfirmExchange} from '../../redux/reducer/giftSlice';

const VoucherDetailPage = () => {
  const giftDetail = useSelector((state) => state.gift.detail);
  const merchantCard = useSelector((state) => state.userInfo.merchantCard);
  const popupShow = useSelector((state) => state.gift.showPopUp);
  const acceptChangeVoucher = useSelector((state) => state.gift.confirmExchange);
  const isRedeeming = useSelector((state) => state.gift.isRedeeming);
  const isCheckingOTP = useSelector((state) => state.gift.isCheckingOTP);

  const {state} = useLocation();
  const phoneNumber = state?.phoneNumber;

  const routeParams = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
  }, []);

  useEffect(() => {
    if (!!phoneNumber && !!routeParams?.giftId) {
      dispatch(getGiftDetailAction({phoneNumber, giftId: routeParams?.giftId}));
    }
  }, [phoneNumber, routeParams?.giftId]);
  useEffect(() => {
    if (!merchantCard) {
      dispatch(getMembershipCardAction({phoneNumber}));
    }
  }, [merchantCard]);

  // const [acceptChangeVoucher, setAcceptChangeVoucher] = useState(false);

  const [inputOTPValue, setInputOTPValue] = useState({value: ''});
  // const [checkOtp, setCheckOtp] = useState();
  // const [checkOTPBtn, setCheckOTPBtn] = useState(false);
  // const [sendOTPBtn, setSendOTPBtn] = useState(false);

  const setAcceptChangeVoucher = (payload) => {
    dispatch(toggleConfirmExchange(payload));
  };

  /*
  useEffect(() => {
    handleCheckOtp();
  }, [checkOTPBtn]);

  const handleCheckOtp = () => {
    if (inputOTPValue?.value === OTP?.value) {
      setCheckOtp(true);
      setCheckOTPBtn(false);
    } else if (inputOTPValue?.value === "") {
      setCheckOtp("");
    } else {
      setCheckOtp(false);
      setCheckOTPBtn(false);
    }
  };
  */

  // useEffect(() => {
  //   if (sendOTPBtn) {
  //     setTimeout(timeOutCallback, 1000);
  //     if (timeReceive === 0) {
  //       setTimeReceive(60);
  //       setSendOTPBtn(false);
  //     }
  //   }
  // }, [sendOTPBtn, timeReceive]);

  // useEffect(() => {
  //   if (checkOtp) {
  //     setPopupShow(true);
  //   }
  // }, [checkOtp]);

  // handleClick when click change voucher
  const handleClickAcceptChangeVoucher = () => {
    setAcceptChangeVoucher(true);
    sendOtpRequest();
  };

  // let OTP = { value: "1234" };

  const sendOtpRequest = () => {
    dispatch(createOtpRedeemGiftAction({phoneNumber}));
  };

  const onConfirmOTP = () => {
    dispatch(
      createRedeemGiftAction({
        phoneNumber,
        giftId: routeParams?.giftId,
        otp: inputOTPValue?.value,
      })
    );
  };

  return (
    <div>
      {popupShow ? (
        <div className="bg-[rgba(10,10,10,0.86)] fixed bottom-0 top-0 left-0 right-0 z-[15]"></div>
      ) : (
        <></>
      )}
      <PopupBlock giftDetails={giftDetail} popupShow={popupShow} />
      <Layout
        // popupShow={popupShow}
        // backBtnPath={ROUTE_NAME.USERINFO}
        title={TITLE.VOUCHER_DETAILS}
        contentFirstCol={
          <VoucherFirstColDetail
            acceptChangeVoucher={acceptChangeVoucher}
            onClickAcceptChangeVoucher={handleClickAcceptChangeVoucher}
            // onClickCheckOTPBtn={() => setCheckOTPBtn(true)}
            // onClickSendOTPBtn={() => setSendOTPBtn(true)}
            onConfirmOTP={onConfirmOTP}
            // sendOTPBtn={sendOTPBtn}
            onChangeOTPValue={(e) => setInputOTPValue({value: e.target.value.replace(/\D/g, '')})}
            // checkOtp={checkOtp}
            otpValue={inputOTPValue?.value}
            phoneNumber={phoneNumber}
            giftDetails={giftDetail}
            merchantPointOfUser={merchantCard?.rewardValue}
            isRedeeming={isRedeeming}
            isCheckingOTP={isCheckingOTP}
          />
        }
        contentSecondCol={
          <VoucherSecondColDetail
            listStore={listStore}
            giftDetails={giftDetail}
          ></VoucherSecondColDetail>
        }
      ></Layout>
    </div>
  );
};
export default VoucherDetailPage;
