import React from 'react';

const Content = (props) => {
  return (
    <div
      // style={props.popupShow ? { filter: "blur(10px)" } : {}}
      className="h-[80vh] mt-24"
    >
      <div className="grid xl:grid-cols-7 lg:grid-cols-8 grid-cols-10 h-full ">
        <div className="xl:col-span-2 lg:col-span-3 col-span-4 relative px-4 border-r border-gray">
          {props.contentFirstCol}
        </div>
        <div className="lg:col-span-5 col-span-6 px-4">{props.contentSecondCol}</div>
      </div>
    </div>
  );
};
export default Content;
