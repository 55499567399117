import {createAsyncThunk} from '@reduxjs/toolkit';
import {getAllGiftApi, getMembershipCardsApi} from '../../apis/third-party';
import {getErrorMessage} from '../../helper/handleError';

export const getAllGiftAction = createAsyncThunk(
  'userInfo/getUserInfo',
  async ({phoneNumber}, {rejectWithValue, fulfillWithValue}) => {
    try {
      const response = await getAllGiftApi(phoneNumber);
      const data = response?.data;
      if (data?.isSuccess && !!data?.data) {
        return fulfillWithValue(data?.data);
      } else {
        throw new Error('Lấy thông tin ưu đãi thất bại!');
      }
    } catch (error) {
      return rejectWithValue(getErrorMessage(error, 'Lấy thông tin ưu đãi thất bại!'));
    }
  }
);

export const getMembershipCardAction = createAsyncThunk(
  'userInfo/getMembershipCard',
  async ({phoneNumber}, {rejectWithValue, fulfillWithValue}) => {
    try {
      const response = await getMembershipCardsApi(phoneNumber);
      const data = response?.data;
      if (data?.isSuccess && !!data?.data) {
        return fulfillWithValue(data?.data);
      } else {
        throw new Error('Lấy thông tin thẻ thành viên thất bại!');
      }
    } catch (error) {
      return rejectWithValue(getErrorMessage(error, 'Lấy thông tin thẻ thành viên thất bại!'));
    }
  }
);
