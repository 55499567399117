const API_ENDPOINTS_HOST = {
  REACT_APP_API_THIRD_PARTY: 'REACT_APP_API_THIRD_PARTY',
};

const GENDERS = {
  M: 'Nam',
  F: 'Nữ',
};

export {API_ENDPOINTS_HOST, GENDERS};
